<template>
  <div class="w-full border bg-gradient-to-tr from-green-100 via-green-500 to-green pt-24">
    <div class="w-10/12 mx-auto flex flex-col items-center justify-center mb-24">
      <form class="w-6/12 p-4 rounded-md bg-white bg-opacity-30 shadow-2xl shadow-gray">
        <div class="mb-6">
          <h2 class="text-lg text-center font-bold text-gray-dark mb-2">Solicitação de Contato</h2>
          <p class="mt-1 text-md  text-center text-gray-dark">
            Queremos ajudá-lo a alcançar resultados incríveis com nossas
            soluções personalizadas.
            Preencha os campos abaixo com suas informações que entraremos em contato com você.
          </p>
        </div>

        <div class="mb-4 px-4">
          <label class="block text-sm/6 font-medium text-gray-900">
            Nome
            <span class="font-bold text-orange">*</span>
          </label>

          <div
              class="flex items-center rounded-md pl-3 mt-2 outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
            <input type="text" name="name" id="name" required autocomplete="name"
                   class="placeholder:italic placeholder:text-gray block min-w-0 grow py-1.5 pr-3 pl-1 text-base border-gray text-gray-900 rounded-md focus:outline-none sm:text-sm/6"
                   placeholder="Digite seu nome" v-model="name"/>
          </div>
        </div>

        <div class="mb-4 px-4">
          <label class="block text-sm/6 font-medium text-gray-900">
            E-mail
            <span class="font-bold text-orange">*</span>
          </label>

          <div
              class="flex items-center rounded-md pl-3 mt-2 outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
            <input type="email" name="email" id="email" required autocomplete="email"
                   class="placeholder:italic placeholder:text-gray block min-w-0 grow py-1.5 pr-3 pl-1 text-base border-gray text-gray-900 rounded-md focus:outline-none sm:text-sm/6"
                   placeholder="Digite seu e-mail" v-model="email"/>
          </div>
        </div>

        <div class="mb-4 px-4">
          <label class="block text-sm/6 font-medium text-gray-900">
            Telefone
            <span class="font-bold text-orange">*</span>
          </label>

          <div
              class="flex items-center rounded-md pl-3 mt-2 outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
            <MaskInput v-model="phone_number" mask="(##) #####-####" id="phone"
                       name="phone" type="tel"
                       class="placeholder:italic placeholder:text-gray block bg-white w-full border border-gray rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                       placeholder="Digite seu telefone" autocomplete="phone" required/>
          </div>
        </div>

        <div class="mb-4 px-4">
          <label for="message" class="block text-sm/6 font-medium text-gray-900">
            Mensagem
            <span class="font-bold text-orange">*</span>
          </label>
          <div class="mt-2">
            <div
                class="flex items-center rounded-md pl-3 outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                    <textarea rows="5" name="message" id="message" required placeholder="Digite Sua mensagem"
                              v-model="message"
                              class="block min-w-0 grow py-1.5 pr-3 pl-1 text-base border-gray text-gray-900 rounded-md focus:outline-none sm:text-sm/6">
                    </textarea>
            </div>
          </div>
        </div>
        <!-- Component: Simple Alert -->
        <div v-if="error"
             class="w-10/12 mx-auto px-4 py-3 text-sm text-red-500 border border-red-100 rounded bg-red-50"
             role="alert">
          <p>{{ error }}</p>
        </div>

        <div v-if="alertSuccess"
             class="w-10/12 mx-auto px-4 py-3 text-sm text-white border bg-green-500 border-white rounded"
             role="alert">
          <p>{{ alertMessage }}</p>
        </div>
        <!-- End Simple  Alert -->

        <div class="w-full flex justify-end mt-4">
          <button class="bg-orange hover:scale-105 text-white font-bold p-2.5 rounded-md"
                  @click.prevent="validateDataBeforeSend">Enviar
          </button>
        </div>
      </form>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import FooterComponent from "@/components/partials/Footer.vue";

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PHONE_REGEX = /^\(?([0-9]{2})\)?([ .-]?)([0-9]{4,5})\2([0-9]{4})$/;
const ERRORS = {
  name: {
    required: "Nome é obrigatório",
    min: "Nome é inválido",
    max: "Nome é inválido",
  },
  phone_number: {
    required: "Telefone é obrigatório",
    min: "Telefone é inválido",
    max: "Telefone é inválido",
  },
  email: 'Email é obrigatório',
  message: {
    required: "Mensagem é obrigatório",
    min: "Mensagem é inválido",
    max: "Nome é inválido",
  },
};
export default {
  name: 'LeadFormComponent',
  components: {
    FooterComponent,
  },
  mounted() {
    this.uri = process.env.VUE_APP_URI
    this.systemCode = process.env.VUE_APP_SYSTEM_LEAD_SOURCE_CODE
  },
  data() {
    return {
      name: '',
      // companyName: '',
      email: '',
      phone_number: '',
      message: '',
      error: '',
      showAlert: false,
      alertSuccess: false,
      alertMessage: '',
    }
  },
  methods: {
    timeoutErrorAlert() {
      setTimeout(() => {
        this.error = null
      }, 3000)
    },
    validateDataBeforeSend() {
      const cleanedPhone = this.phone_number.replace(/[\s().-]/g, '');
      const validations = [
        {test: !!this.name, error: ERRORS.name.required},
        {test: this.name.length >= 3, error: ERRORS.name.min},
        {test: this.name.length <= 254, error: ERRORS.name.max},
        {test: PHONE_REGEX.test(cleanedPhone) && this.phone_number, error: ERRORS.phone_number.required},
        {test: this.phone_number.length >= 11, error: ERRORS.phone_number.min},
        {test: this.phone_number.length <= 19, error: ERRORS.phone_number.max},
        {test: this.email && EMAIL_REGEX.test(this.email), error: ERRORS.email},
        {test: !!this.message, error: ERRORS.message.required},
        {test: this.message.length >= 3, error: ERRORS.message.min},
        {test: this.message.length <= 254, error: ERRORS.name.max},
      ];

      for (const validation of validations) {
        if (!validation.test) {
          this.error = validation.error;
          this.timeoutErrorAlert();
          return;
        }
      }

      this.error = null;
      this.sendEmail();
    },

    sendEmail() {
      const body = {
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        message: this.message,
        lead_source_code: this.systemCode,
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${this.uri}/api/lead/cadastro`, options)
          .then(response => response.json().then(data => ({
            status: response.status,
            body: data,
          })))
          .then(data => {
            if (data.status === 201) {
              this.showAlert = true;
              this.alertSuccess = true;
              this.alertMessage = data.body.message;

              setTimeout(() => {
                this.showAlert = false;
              }, 3000);

              // Reset form field
              this.name = '';
              this.email = '';
              this.message = '';
              this.phone_number = '';
            }
            if (data.status !== 201) {
              this.showAlert = true;
              this.alertSuccess = false;
              this.alertMessage = 'Deu ruim'//Object.values(data.body.errors)[0][0];

              setTimeout(() => {
                this.showAlert = false;
              }, 5000);
            }
          })
    }
  }
}
</script>
