<template>

  <button :class="'bg-green hover:scale-105 py-2 px-4 rounded-full'">
    <a :href="link" target="_blank" :title="title">
      <span class="text-xl text-white lg:text-2xl uppercase">{{ title }}</span>
    </a>
  </button>

</template>
<script>
export default {
  name: 'PrimaryButton',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>