<template>
  <div id="contato"
       class="w-full h-fit mx-auto flex flex-col justify-center items-center overflow-hidden-x mb-12">
    <!-- Component: Success Alert With Icon -->
    <transition name="bounce">
      <div v-if="showAlert" ref="alert"
           :class="{'flex items-start w-11/12 lg:w-4/12 gap-4 px-4 py-3 text-sm border rounded border-red-100 bg-red-50 text-red-500' : alertError,
           'flex items-start w-11/12 lg:w-4/12 gap-4 px-4 py-3 text-sm border rounded border-green bg-green-100 text-green-500': alertSuccess}"
           role="alert">
        <!-- Icon -->
        <svg v-if="alertSuccess" aria-labelledby="title-01 desc-01" class="w-5 h-5" fill="none" role="graphics-symbol"
             stroke="currentColor"
             stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <title id="title-01">Ícone</title>
          <desc id="desc-01">É um simples ícone de alerta</desc>
          <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-if="alertError" aria-labelledby="title-04 desc-04" class="w-5 h-5" fill="none" role="graphics-symbol"
             stroke="currentColor"
             stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <title id="title-04">Icon title</title>
          <desc id="desc-04">A more detailed description of the icon</desc>
          <path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <!-- Text -->
        <p>{{ messageAlert }}</p>
      </div>
    </transition>
    <!-- End Success Alert With Icon -->
    <div class="w-full flex flex-col items-center justify-center mb-4">
      <h2 class="text-green uppercase text-md mb-2 font-bold text-3xl">Entre em contato</h2>
      <p class="text-xl">Que tal fecharmos uma parceria?</p>
    </div>
    <div class="w-full flex flex-col items-center justify-center">
      <form class="flex flex-col items-center space-y-2 relative h-full p-4 rounded-md shadow-xl border-2 border-orange shadow-gray" method="post">
        <input type="text" v-model="name" name="name"
               class="placeholder:italic placeholder:text-gray block bg-white w-full border border-gray rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
               autocomplete="name" placeholder="Digite seu nome"/>
        <MaskInput v-model="phone" mask="(##) #####-####"  name="phone" type="text"
                   class="placeholder:italic placeholder:text-gray block bg-white w-full border border-gray rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                   placeholder="Digite seu telefone" autocomplete="phone"/>
        <input v-model="email" name="email" type="text"
               class="placeholder:italic placeholder:text-gray block bg-white w-full border border-gray rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
               placeholder="Digite sem email" autocomplete="email"/>
        <textarea name="message" v-model="message" cols="30" rows="10"
                  class="placeholder:italic placeholder:text-gray block bg-white w-full border border-gray rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                  placeholder="Fale um pouco sobre seu projeto! Vamos encontrar a melhor solução juntos."></textarea>

        <!-- Component: Simple Danger Alert -->
        <div v-if="error" class="w-10/12 px-4 py-3 text-sm text-red-500 border border-red-100 rounded bg-red-50"
             role="alert">
          <p>{{ error }}</p>
        </div>
        <!-- End Simple Danger Alert -->

        <div class="w-full flex justify-end mt-4">
          <button class="bg-orange hover:scale-105 text-white font-bold p-2.5 rounded-md"
                  @click.prevent="validateDataBeforeSend">Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import emailjs from 'emailjs-com';
import { MaskInput } from 'vue-3-mask';

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PHONE_REGEX = /^\(?([0-9]{2})\)?([ .-]?)([0-9]{4,5})\2([0-9]{4})$/;
const ERRORS = {
  name: 'Nome é obrigatório',
  phone: 'Telefone é obrigatório',
  email: 'Email é obrigatório',
  message: 'Mensagem é obrigatória'
};
export default {
  name: 'ContactComponent',
  components: {
    MaskInput,
  },
  data() {
    return {
      email: '',
      name: '',
      phone: '',
      message: '',
      service_id: '',
      template: '',
      public_token: '',
      showAlert: true,
      messageAlert: '',
      error: null,
      alertError: false,
      alertSuccess: false
    }
  },
  mounted() {
    this.service_id = process.env.VUE_APP_EMAILJS_SERVICE_ID
    this.template =  process.env.VUE_APP_EMAILJS_TEMPLATE_ID
    this.public_token = process.env.VUE_APP_EMAILJS_PUBLIC_TOKEN
  },
  methods: {
    timeoutErrorAlert() {
      setTimeout(() => {
        this.error = null
      }, 3000)
    },
    validateDataBeforeSend() {
      const validations = [
        {test: !!this.name, error: ERRORS.name},
        {test: this.phone && this.phone.length >= 11 && PHONE_REGEX.test(this.phone), error: ERRORS.phone},
        {test: this.email && EMAIL_REGEX.test(this.email), error: ERRORS.email},
        {test: !!this.message, error: ERRORS.message}
      ];

      for (const validation of validations) {
        if (!validation.test) {
          this.error = validation.error;
          this.timeoutErrorAlert();
          return;
        }
      }

      this.error = null;
      this.sendEmail();
    },

    sendEmail() {
      let form = document.querySelector('form');
      try {

        emailjs.sendForm(this.service_id, this.template, form,
           this.public_token, {})
        this.showAlert = true;
        this.alertSuccess = true;
        this.messageAlert = 'Mensagem enviada com sucesso!!!'

        setTimeout(() => {
          this.showAlert = false;
        }, 3000)

      } catch (error) {
        this.showAlert = true;
        this.alertError = true;
        this.messageAlert = 'Tivemos um problema ao enviar a mensagem, tente novamente.'
        setTimeout(() => {
          this.showAlert = false;
        }, 3000)
        // console.log({error})
      }


      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
      this.phone = ''
    }
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in-right 1s;
}

.bounce-leave-active {
  animation: bounce-out-right 1s;
}

@keyframes bounce-in-right {
  0% {
    transform: translateX(100%);
  }
  50%, 100% {
    transform: translateX(0);
  }
}

@keyframes bounce-out-right {
  0% {
    transform: translateX(0);
  }
  50%, 100% {
    transform: translateX(200%);
  }
}
</style>