<template>
  <div id="produtos"
       class="w-10/12 h-fit mx-auto flex flex-col justify-center lg:mb-12 items-center overflow-hidden-x">
    <section class="py-12 bg-white">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap items-center -mx-4 mb-16">
          <div class="w-full md:w-1/2">
            <span
                class="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm">
              Nossos Produtos
            </span>
            <h2 class="mb-6 text-4xl md:text-5xl leading-tight font-bold tracking-tighter">
              Oferecemos soluções prontas para uso, impulsione o crescimento do seu negócio.</h2>
            <p class="text-lg md:text-xl text-coolGray-500 font-medium">
              Otimize seus processos, aumente sua produtividade, reduza custos e alcance novos patamares de sucesso.
            </p>
          </div>
        </div>

        <!-- Component: Feature item with icon title and text -->
        <div class="w-full flex flex-col md:flex-row justify-around">
          <!--   whaticket     -->
          <!--  image        -->
          <div class="w-full md:w-6/12 mb-12 md:mb-0 flex justify-center items-center">
            <img src="../../assets/whaticket.webp" alt="Imagem do sistema whaticket" width="746" height="420">
          </div>
          <div class="w-full md:w-6/12 flex flex-col items-center text-center">
            <div class="flex flex-col items-center justify-center w-full min-w-0 gap-0 text-base">
              <h3 class="mb-4 text-lg md:text-xl text-coolGray-500 font-bold">Aumente suas vendas pelo WhatsApp! </h3>
              <p class="text-lg md:text-xl text-coolGray-500 font-medium">
                Com a nossa ferramenta de gestão de chats, você centraliza todas as
                suas conversas, automatiza respostas e acompanha o desempenho de cada campanha. <br>
                Configure chatbots para responder perguntas
                frequentes, agendar mensagens e automatizar processos, economizando tempo e recursos.<br>
                Você pode automatizar seus fluxos de trabalho com integrações fluidas entre
                <span class="font-bold">OpenAI, TypeBot, DialogFlow, N8N e Webhooks.</span><br>
                Crie chatbots inteligentes que entendem a linguagem natural e personalizam a experiência do usuário.<br>
                Transforme o WhatsApp em um canal de vendas poderoso.
              </p>
            </div>
          </div>
        </div>
        <!-- End Feature item with icon title and text -->
      </div>
    </section>

    <section class="bg-white">
      <div class="container px-4 mx-auto">
        <div class="max-w-4xl mx-auto text-center">
          <h2 class="mb-4 text-3xl md:text-4xl font-heading font-bold">Simplifique o seu marketing pelo WhatsApp. </h2>
          <p class="mb-12 text-lg md:text-xl font-heading font-medium text-coolGray-500">
            Nossa plataforma é intuitiva e fácil de usar, mesmo que você não seja um especialista.
            Crie campanhas eficazes, aumente o engajamento e alcance seus objetivos de negócio.
          </p>
          <primary-button
             link="https://chat.tiagoneitzke.com.br/signup" title="criar conta"/>

          <button class="text-orange hover:scale-105 p-2 lg:w-48">
            <router-link to="/whaticket" title="Saiba mais">
              <span class="text-xl lg:text-2xl uppercase font-bold">Saiba Mais</span>
            </router-link>
          </button>

        </div>
      </div>
    </section>
  </div>
</template>
<script>
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

export default {
  name: 'ProductsComponent',
  components: {PrimaryButton},
}
</script>
